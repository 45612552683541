// La estructura de este archivo, está basada en las vistas existentes en la aplicación.
// Por favor conservar la estructura lang > view > component > section : value
// Las llaves del objeto, deben repetirse tanto en inglés, como en español, teniendo el mismo nombre en ambos, por ejemplo
/* 
    {
        "en": {
            "title": "Hello world"   //La llave siempre es la misma, tanto en inglés como en español
        },
        "es": {
            "title": "Hola mundo"
        }
    }
*/

import navbarTextData from 'layouts/lang';
import { contactTextDataEN, contactTextDataES } from 'views/Contact/index.lang';
import { landingTextDataEN, landingTextDataES } from 'views/Landing/index.lang';
import { blogReachTextDataEN, blogReachTextDataES } from 'views/BlogReachView/index.lang';
import { blogArticleTextDataEN, blogArticleTextDataES } from 'views/BlogArticle/index.lang';

const globalMessages = {
  //Language level
  en: {
    // View level
    ...navbarTextData.en,
    ...landingTextDataEN,
    ...contactTextDataEN,
    ...blogReachTextDataEN,
    ...blogArticleTextDataEN

  },
  es: {
    ...navbarTextData.es,
    ...landingTextDataES,
    ...contactTextDataES,
    ...blogReachTextDataES,
    ...blogArticleTextDataES
  },
};

export default globalMessages;
