import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import globalMessages from './global';


const defaultLanguageHandler = () => {    
  const lastUserLanguage = window.localStorage.getItem('language');
  console.log(lastUserLanguage);
  if (lastUserLanguage) {
    return lastUserLanguage;
  }
  const explorerDefaultLanguage = (navigator.languages && navigator.languages[0]) ||
  navigator.language || navigator.userLanguage;
  const explorerLanguageWithoutRegion = explorerDefaultLanguage.toLowerCase().split(/[_-]+/)[0];
  if ( explorerDefaultLanguage != 'es' || explorerDefaultLanguage != 'en' ) {
    return 'en';
  }
  return explorerLanguageWithoutRegion;
};

const langContext = React.createContext();

const LangProvider = (props) => {
  const [textData, setTextData] = useState(() => globalMessages[defaultLanguageHandler()]);
  const [language, setLanguage] = useState(() => {
    const lastUserLanguage = window.localStorage.getItem('language');
    console.log(lastUserLanguage);
    if (lastUserLanguage) {
      return lastUserLanguage;
    }
    const defaultLanguage = defaultLanguageHandler();
    if ( defaultLanguage != 'es' || defaultLanguage != 'en' ) {
      return 'en';
    }
    return defaultLanguage;
  });

  const changeLanguage = (language) => {
    if (language == 'es') {
      window.localStorage.setItem('language', 'es');
      setLanguage('es');
      setTextData(globalMessages['es']);
      return;
    }
    if (language == 'en') {
      window.localStorage.setItem('language', 'en');
      setLanguage('en');
      setTextData(globalMessages['en']);
      return;
    }
    throw Error('Set a correct language -> "en" or "es"');
  };
  return (
    <langContext.Provider
      value={{
        changeLanguage: changeLanguage,
        value: language
      }}
    >
      <IntlProvider locale={language} messages={textData}>
        {props.children}
      </IntlProvider>
    </langContext.Provider>
  );
};

LangProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export { LangProvider, langContext };
