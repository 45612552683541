const lastStoriesTextData = {
  en: {
    'laststories.title.1': 'Latest posts',
    'laststories.title.2': 'Find out about the latest marketing news.',
    'laststories.1.paragraph.1': 'Digital marketing strategies to enhance your sales channels',
    'laststories.1.paragraph.2': 'In an increasingly connected and digitalized world, having effective digital marketing strategies that drive the growth of your business is key.',
    'laststories.2.paragraph.1': 'Paid media: ¡una estrategia para mejorar tu negocio!',
    'laststories.2.paragraph.2': 'Se centra en entender dónde están las audiencias que quieres impactar, y llegar a ellas mediante la compra de espacios publicitarios.',
    'laststories.3.paragraph.1': 'Emailing: The art of connecting with your community and empowering it. The art of relating to your audience through valuable content.',
    'laststories.3.paragraph.2': 'In a constantly evolving digital world, emailing, better known as email marketing strategy, has become an indispensable tool for educational institutions seeking to connect effectively with their community.',
    'laststories.4.paragraph.1': 'Customer Journey in the Automotive Industry: Know its Importance.',
    'laststories.4.paragraph.2': 'In the competitive automotive industry, understanding the Customer Journey is essential to stand out. Every time a customer interacts with your brand, it is part of their Customer Journey. From the moment they research a new model online to after-sales service, every touchpoint counts.',
    'laststories.5.paragraph.1': 'Link building: Guide to improve your domain authority',
    'laststories.5.paragraph.2': 'Link building is essential to improve the authority of your domain and is an essential strategy in the world of SEO. This technique not only increases the relevance and positioning of your website in search engines, but also attracts quality traffic and establishes your brand as a benchmark in the industry.',
  },
  es: {
    'laststories.title.1': 'Últimas entradas',
    'laststories.title.2': 'Aquí está en lo que hemos estado trabajando recientemente.',
    'laststories.1.paragraph.1': 'Estrategias de marketing digital para potenciar tus canales de venta',
    'laststories.1.paragraph.2': 'En un mundo cada vez más conectado y digitalizado, es fundamental contar con estrategias de marketing digital efectivas que impulsen el crecimiento de tu negocio.',
    'laststories.2.paragraph.1': 'Paid media: ¡una estrategia para mejorar tu negocio!',
    'laststories.2.paragraph.2': 'Se centra en entender dónde están las audiencias que quieres impactar, y llegar a ellas mediante la compra de espacios publicitarios.',
    'laststories.3.paragraph.1': 'Emailing: El arte de conectar con tu comunidad y potenciarla. El arte de relacionarte con tu audiencia a través de contenido de valor. ',
    'laststories.3.paragraph.2': 'En un mundo digital en constante evolución, el emailing, más conocido como estrategia de email marketing, se ha convertido en una herramienta indispensable para las instituciones educativas que buscan conectar de forma efectiva con su comunidad.',
    'laststories.4.paragraph.1': 'Customer Journey en la Industria Automotriz: Conoce su Importancia.',
    'laststories.4.paragraph.2': 'En la competitiva industria automotriz, entender el Customer Journey es fundamental para sobresalir. Cada vez que un cliente interactúa con tu marca forma parte de su Customer Journey. Desde el momento en que investigan un nuevo modelo en línea hasta el servicio postventa, cada punto de contacto cuenta.',
    'laststories.5.paragraph.1': 'Link building: Guía para mejorar tu autoridad de dominio',
    'laststories.5.paragraph.2': 'El link building es fundamental para mejorar la autoridad de tu dominio y es una estrategia esencial en el mundo del SEO. Esta técnica no solo incrementa la relevancia y el posicionamiento de tu sitio web en los motores de búsqueda, sino que también atrae tráfico de calidad y establece tu marca como un referente en la industria.',
  }
};

export default lastStoriesTextData;
