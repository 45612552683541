/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  /*   List,
  ListItemIcon, */
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const mockList = [
  {
    textId: 'landing.product.p2.li.1',
    textSecondary: null,
  },
  {
    textId: 'landing.product.p2.li.2',
    textSecondary: null,
  },
  {
    textId: 'landing.product.p2.li.3',
    textSecondary: null,
  },
  {
    textId: 'landing.product.p2.li.4',
    textSecondary: null,
  },
];

const FeatureList = () => {
  const theme = useTheme();

  return (
    <Grid 
      container 
      display={'flex'} 
      flexDirection={'column'}
      alignItems={'center'} 
      justifyContent={'center'} 
      spacing={3} 
      width={1} 
      margin={0}
    >
      <Grid item xs={12} marginBottom={2}>
        <Typography
          align={'center'}
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
          gutterBottom
          color={'primary'}
        >
          <FormattedMessage id={'landing.product.title.1'} />{' '}
        </Typography>
        <Typography
          variant={'h3'}
          marginBottom={{ xs: 5, md: 5 }}
          align={'center'}
          sx={{ fontWeight: 700 }}
          gutterBottom
        >
          <FormattedMessage id={'landing.product.title.2'} />
        </Typography>
      </Grid>
      <Grid item xs={12} marginBottom={{ xs: 5, md: 5 }}>
        <Typography color="text.secondary" variant={'h6'}>
          <FormattedMessage id={'landing.product.p1.1'} />{' '}
          <Typography
            color="text.secondary"
            component={'span'}
            variant={'h6'}
            sx={{
              fontWeight: 700,
            }}
          >
            <FormattedMessage id={'landing.product.p1.2'} />{' '}
          </Typography>
          <FormattedMessage id={'landing.product.p1.3'} />{' '}
          <Typography
            color="text.secondary"
            component={'span'}
            variant={'h6'}
            sx={{
              fontWeight: 700,
            }}
          >
            <FormattedMessage id={'landing.product.p1.4'} />{' '}
          </Typography>
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        {[
          'landing.product.p1.li.1',
          'landing.product.p1.li.2',
          'landing.product.p1.li.3',
          'landing.product.p1.li.4',
          'landing.product.p1.li.5',
          'landing.product.p1.li.6'
        ].map((itemIntlId, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Box
              component={ListItem}
              disableGutters
              width={{ xs: 1, md: 8 / 9}}
              padding={0}
            >
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.primary.main}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText primary={
                <React.Fragment>
                  <FormattedMessage id={itemIntlId} />
                </React.Fragment>
              } />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        width={{ xs: 8/9, sm: 1}}
      >
        <Grid item center xs={12} sm={8} md={6} marginTop={6} width={1}>
          <Accordion width={1}>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownCircleIcon 
                  sx={{ 
                    color: theme.palette.primary.main,
                    fontSize: 35
                  }} 
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                /* align={{xs: 'left', md: 'center'}} */
                align="center"
                width={'90%'}
              >
                <FormattedMessage id={'landing.product.p2.1'} />{' '} 
                <Typography
                  component={'span'}
                  sx={{
                    fontWeight: 700
                  }}
                >
                  <FormattedMessage id={'landing.product.p2.2'} />{' '} 
                </Typography> 
                <FormattedMessage id={'landing.product.p2.3'} />{' '} 
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {mockList.map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.primary.dark}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary={
                    <React.Fragment>
                      <FormattedMessage id={item.textId} />
                    </React.Fragment>
                  } />
                </ListItem>
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FeatureList;
