const heroTextData = {
  en: {
    'landing.title.1':'We are the',
    'landing.title.2': 'strategic solution',
    'landing.title.3': 'for the growth of your business',
    'landing.calltoaction.1': 'Let\'s talk about your business',
    'landing.info.1': 'We enhance the value of your company through',
    'landing.info.2': 'digital marketing strategies and ',
    'landing.info.3': 'integrated technological tools,',
    'landing.info.4': 'focused on the growth of your sales and the optimization of your investment.'
  },
  es: {
    'landing.title.1':'Somos la',
    'landing.title.2': 'solución estratégica',
    'landing.title.3': 'para el crecimiento de tu negocio',
    'landing.calltoaction.1': 'Hablemos de tu negocio',
    'landing.info.1': 'Potenciamos el valor de tu empresa a través de ',
    'landing.info.2': 'estrategias de marketing digital y herramientas',
    'landing.info.3': 'tecnológicas integradas,',
    'landing.info.4': 'enfocadas en el crecimiento de tus ventas y la optimización de tu inversión.'
  }
};

export default heroTextData;
