import React from 'react';

import {
  Landing as LandingView,
  Contact as ContactView,
  BlogReachView as BlogReachView,
  BlogArticle as BlogArticleView,
  BlogArticleTwo as BlogArticleTwoView,
  BlogArticleThree as BlogArticleThreeView,
  BlogArticleFour as BlogArticleFourView,
  BlogArticleFive as BlogArticleFiveView,
} from 'views';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <LandingView {...params} />
  },
  {
    path: '/contact',
    renderer: (params = {}) => <ContactView {...params} />
  },
  {
    path: '/blog',
    renderer: (params = {}) => <BlogReachView {...params} />
  },
  {
    path: '/blog/marketing-digital-potencia-canales-de-venta',
    renderer: (params = {}) => <BlogArticleView {...params} />
  },
  {
    path: '/blog/paid-media-una-estrategia-para-mejorar-tu-negocio',
    renderer: (params = {}) => <BlogArticleTwoView {...params} />
  },
  {
    path: '/blog/email-marketing-en-el-sector-educativo',
    renderer: (params = {}) => <BlogArticleThreeView {...params} />
  },
  {
    path: '/blog/customer-journey-en-la-industria-automotriz-conoce-su-importancia',
    renderer: (params = {}) => <BlogArticleFourView {...params} />
  },
  {
    path: '/blog/link-building-guia-para-mejorar-tu-autoridad-de-dominio',
    renderer: (params = {}) => <BlogArticleFiveView {...params} />
  }
];

export default routes;
