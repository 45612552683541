const productTextData = {
  en: {
    'landing.product.title.1':
      'We integrate the management in a single process',
    'landing.product.title.2': 'End2End Performance',
    'landing.product.p1.1': 'Our work begins',
    'landing.product.p1.2':
      'in the search and decision making of the user to the closing',
    'landing.product.p1.3':
      'of the transaction, this is supported at each stage by data analytics that allows us',
    'landing.product.p1.4':
      'to generate feedback, implement improvement actions and make business decisions.',
    'landing.product.p1.li.1':
      'Strategy and planning of the digital sales channel',
    'landing.product.p1.li.2':
      'Development of digital ecosystems (landing pages, web and mobile chatbot, platform integrations)',
    'landing.product.p1.li.3': 'Contact center service (chatbot and voice)',
    'landing.product.p1.li.4':
      'Generation of leads, construction, and profiling of audiences',
    'landing.product.p1.li.5':
      'Data intelligence (Automation, centralization, deployment, models, ML, AI)',
    'landing.product.p1.li.6': 'Inbound marketing strategy and 1:1 channels',
    'landing.product.p2.1': 'We drive our services with',
    'landing.product.p2.2': 'value-added',
    'landing.product.p2.3': 'strategy and tactics:',
    'landing.product.p2.li.1': 'Search Engine Optimization (SEO)',
    'landing.product.p2.li.2':
      'Planning and purchase of digital media focused on conversion',
    'landing.product.p2.li.3':
      'Implementation of a CRM platform and automated processes',
    'landing.product.p2.li.4': 'Integrations and Tagging (Martech y Adtech)',
    'landing.product.cards.title.1': 'Prospects',
    'landing.product.cards.text.1':
      'Implementation of strategies for the creation of segmented audiences that allow the delivery of quality leads to the model.',
    'landing.product.cards.title.2': 'Engagement',
    'landing.product.cards.text.2':
      'We connect with your customers at the ideal moment and with the right message, based on personalization at each stage of the process.',
    'landing.product.cards.title.3': 'Sales',
    'landing.product.cards.text.3':
      'Execution of conversion strategies, hand in hand with transactional assets, which guarantee commercial closure.',
    'landing.product.cards.title.4': 'Loyalty',
    'landing.product.cards.text.4':
      'Management of eCustomer Services, database feedback adjustments, surveys and loyalty plans.',
  },
  es: {
    'landing.product.title.1':
      'Integramos la gestión de un cliente en un solo proceso',
    'landing.product.title.2': 'End2End Performance',
    'landing.product.p1.1': 'Estamos presentes desde',
    'landing.product.p1.2': 'la búsqueda y toma de decisión hasta el cierre',
    'landing.product.p1.3':
      'de la transacción, esto soportado en cada etapa por la analítica de datos que nos permite',
    'landing.product.p1.4':
      'generar aprendizajes, implementar acciones de mejora y tomar decisiones de negocio.',
    'landing.product.p1.li.1':
      'Estrategia y planeación del canal comercial digital',
    'landing.product.p1.li.2':
      'Generación de leads, construcción y perfilación de audiencias ',
    'landing.product.p1.li.3':
      'Desarrollo de ecosistemas digitales (Landing pages, chatbot web y mobile, integraciones con plataformas)',
    'landing.product.p1.li.4':
      'Inteligencia de datos (Automatización, centralización, despliegue, modelos, ML, AI) ',
    'landing.product.p1.li.5': 'Servicio de contact center (chatbot y voz)',
    'landing.product.p1.li.6': 'Estrategia de inbound marketing y canales 1:1',
    'landing.product.p2.1': 'Impulsamos nuestros servicios con',
    'landing.product.p2.2': 'estrategia y tácticas',
    'landing.product.p2.3': 'de valor agregado:',
    'landing.product.p2.li.1': 'Search Engine Optimization (SEO) ',
    'landing.product.p2.li.2':
      'Planeación y compra de medios digitales enfocados en conversión',
    'landing.product.p2.li.3': 'Implementación de procesos de automation y CRM',
    'landing.product.p2.li.4': 'Integraciones/Tagging (Martech y Adtech) ',
    'landing.product.cards.title.1': 'Prospectos',
    'landing.product.cards.text.1':
      'Implementación de estrategias para la construcción de audiencias segmentadas que permiten la entrega de leads de calidad.',
    'landing.product.cards.title.2': 'Relacionamiento',
    'landing.product.cards.text.2':
      'Conectamos con tus clientes en el momento ideal y con el mensaje correcto, a partir de la personalización en cada etapa del proceso.',
    'landing.product.cards.title.3': 'Venta',
    'landing.product.cards.text.3':
      'Ejecución de estrategias de conversión, de la mano de activos transaccionales, que garanticen el cierre comercial.',
    'landing.product.cards.title.4': 'Fidelización',
    'landing.product.cards.text.4':
      'Gestión de eCustomer Services, actualización de bases de datos, encuestas y planes de fidelización.',
  },
};

export default productTextData;
