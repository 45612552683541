const navbarTextData = {
  en: {
    'navbar.experience': 'Experience',
    'navbar.ourpromise': 'Our promise',
    'navbar.businessmodel': 'Our Business Model',
    'navbar.ally': 'Allies',
    'navbar.blog': 'Blog',
    'navbar.contact': 'Contact',
    'navbar.language.spanish': 'Español (ES)',
    'navbar.language.english': 'English (EN)',
    'navbar.language.title': 'Language',
    'footer.rights': 'Merge 2022, All rights reserved.',
    'footer.privacy': 'Privacy policies',
    'newsletter.title': 'Subscribe to our newsletter',
    'newsletter.p': 'Be one of the first to know about our upcoming news and updates',
    'newsletter.placeholder': 'Enter your email',
    'newsletter.calltoaction': 'Subscribe'
  },
  es: {
    'navbar.experience': 'Experiencia',
    'navbar.ourpromise': 'Nuestra promesa',
    'navbar.businessmodel': 'Modelo de negocio',
    'navbar.ally': 'Aliados',
    'navbar.blog': 'Blog',
    'navbar.contact': 'Contacto',
    'navbar.language.spanish': 'Español (ES)',
    'navbar.language.english': 'English (EN)',
    'navbar.language.title': 'Idioma',
    'footer.rights': 'Merge 2022, todos los derechos reservados.',
    'footer.privacy': 'Politicas de privacidad', 
    'newsletter.title': 'Suscríbete a nuestro boletín',
    'newsletter.p': 'Sé uno de los primeros en conocer nuestras próximas noticias y actualizaciones.',
    'newsletter.placeholder': 'Ingresa tu correo electrónico',
    'newsletter.calltoaction': 'Suscríbete'
  }
};

export default navbarTextData;
