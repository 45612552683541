const sidebarArticlesTextData = {
  en: {
    'sidebararticles.title.1':'Related entries',
    'sidebararticles.1.paragraph.1':'Paid media: ¡una estrategia para mejorar tu negocio!',
    'sidebararticles.2.paragraph.1':'Digital marketing strategies to enhance your sales channels',
    'sidebararticles.3.paragraph.1':'Emailing: The art of connecting with your community and empowering it. The art of relating to your audience through valuable content.',
    'sidebararticles.read-more': 'Read more'
  },
  es: {
    'sidebararticles.title.1':'Entradas relacionadas',
    'sidebararticles.1.paragraph.1':'Paid media: ¡una estrategia para mejorar tu negocio!',
    'sidebararticles.2.paragraph.1':'Estrategias de marketing digital para potenciar tus canales de venta',
    'sidebararticles.3.paragraph.1':'Emailing: El arte de conectar con tu comunidad y potenciarla. El arte de relacionarte con tu audiencia a través de contenido de valor.',
    'sidebararticles.read-more': 'Leer más'
  }
};

export default sidebarArticlesTextData;
  