const teamTextData = {
  en: {
    'landing.value.title.1': 'We generate value,',
    'landing.value.title.2': 'we win together',
    'landing.value.p.1': 'We are a team of',
    'landing.value.p.2':
      'passionate and digital experts with more than 10 years of experience',
    'landing.value.p.3':
      'supporting the growth of various industries, through the',
    'landing.value.p.4': 'optimization of the user and consumer experience,',
    'landing.value.p.5':
      'developing performance marketing strategies, technology products, contact center and data.',
    'landing.value.li.1': 'Our business model',
    'landing.value.li.1.1': 'is based on results.',
    'landing.value.li.2': 'We are an ',
    'landing.value.li.2.1': 'extension of your commercial',
    'landing.value.li.2.2': 'and service team.',
    'landing.value.li.3':'We measure, analyze and optimize ',
    'landing.value.li.3.1': 'automated data in real time.',
    'landing.value.li.3.2': '',
    'landing.value.li.4': 'We work under',
    'landing.value.li.4.1': 'agile methodologies.',
    'landing.value.li.4.2': ' ',
  },
  es: {
    'landing.value.title.1': 'Generamos valor',
    'landing.value.title.2': 'para ganar juntos',
    'landing.value.p.1': 'Somos un equipo de',
    'landing.value.p.2':
      'apasionados y expertos digitales con más de 10 años de experiencia',
    'landing.value.p.3':
      'apoyando el crecimiento de diversas industrias, a través de la',
    'landing.value.p.4':
      'optimización en la experiencia del usuario y consumidor,',
    'landing.value.p.5':
      'desarrollando estrategias de performance marketing, productos de tecnología, contact center y data.',
    'landing.value.li.1': 'Nuestro modelo de negocio',
    'landing.value.li.1.1': 'está basado en resultados',
    'landing.value.li.1.2': '',
    'landing.value.li.2': 'Somos una',
    'landing.value.li.2.1': 'extensión de tu equipo comercial',
    'landing.value.li.2.2': ' y de servicio.',
    'landing.value.li.3': 'Medimos, analizamos y optimizamos ',
    'landing.value.li.3.1': 'de manera automatizada y en tiempo real.',
    'landing.value.li.3.2': '',
    'landing.value.li.4': 'Trabajamos bajo',
    'landing.value.li.4.1': 'metodologías ágiles.',
    'landing.value.li.4.2': ' '
  },
};

export default teamTextData;
