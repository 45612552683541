const heroContentTextData = {
  en: {
    'blogarticle.title': 'Digital marketing strategies to enhance your sales channels',
    'blogarticle.2.title': 'Paid media: ¡una estrategia para mejorar tu negocio!',
    'blogarticle.3.title': 'Emailing: The art of connecting with your community and empowering it. The art of relating to your audience through valuable content.',
    'blogarticle.4.title': 'Customer Journey in the Automotive Industry: Know its Importance.',
    'blogarticle.5.title': 'Link building: Guide to improve your domain authority',
  },
  es: {
    'blogarticle.title': 'Estrategias de marketing digital para potenciar tus canales de venta',
    'blogarticle.2.title': 'Paid media: ¡una estrategia para mejorar tu negocio!',
    'blogarticle.3.title': 'Emailing: El arte de conectar con tu comunidad y potenciarla. El arte de relacionarte con tu audiencia a través de contenido de valor.',
    'blogarticle.4.title': 'Customer Journey en la Industria Automotriz: Conoce su Importancia.',
    'blogarticle.5.title': 'Link building: Guía para mejorar tu autoridad de dominio',
  }
};

export default heroContentTextData;
  