import React from 'react';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
const S3_URL = process.env.REACT_APP_S3_URL;
const mock = [
  {
    url: S3_URL + '/logo-google.svg',
    company: 'Google',
    maxWidth: 180,
    marginX: 0,
    marginY: 0,
  },
  {
    url: S3_URL + '/logo-botmaker.webp',
    company: 'Botmaker',
    maxWidth: 180,
    marginX: 1,
    marginY: 0,
  },
  {
    url: S3_URL + '/logo-hubspot.svg',
    company: 'Hubspot',
    maxWidth: 180,
    marginX: 1,
    marginY: 0,
  },
  {
    url: S3_URL + '/logo-sharpspring.svg',
    company: 'Sharpspring',
    maxWidth: 180,
    marginX: 1,
    marginY: 0,
  },
  {
    url: S3_URL + '/logo-facebook.svg',
    company: 'Facebook',
    maxWidth: 180,
    marginX: 1,
    marginY: 0,
  },
  {
    url: S3_URL + '/logo-datorama.svg',
    company: 'Saleforce - Datorama',
    maxWidth: 180,
    marginX: 1,
    marginY: 0,
  },
  {
    url: S3_URL + '/logo-azure.svg',
    company: 'Microsoft Azure',
    maxWidth: 180,
    marginX: 0,
    marginY: 0,
  },
  {
    url: S3_URL + '/logo-aws.svg',
    company: 'AWS',
    maxWidth: 180,
    marginX: 0,
    marginY: 0,
  },
];

const Partners = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  let slidesToShow = 2;
  if (isXs) {
    slidesToShow = 2;
  }
  if (isSm) {
    slidesToShow = 3;
  }
  if (isMd) {
    slidesToShow = 4;
  }
  if (isLg) {
    slidesToShow = 5;
  }

  const sliderOpts = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <React.Fragment>
      <Box
        marginY={10}
        bgcolor='background.paper'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant={'h4'}
          sx={{
            textAlign: 'center',
          }}
          width={{xs: 3/4, md: 4/5}}
        >
          <FormattedMessage id={'landing.allies.1'} />{' '}
          <Typography variant={'h4'} component={'span'} fontWeight={700}>
            <FormattedMessage id={'landing.allies.2'} />{' '}
          </Typography>
          <FormattedMessage id={'landing.allies.3'} />{' '}
        </Typography>
      </Box>
      <Box bgcolor={'primary.main'} py={6}>
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box
              maxWidth={item.maxWidth ? item.maxWidth : 120}
              marginX={item.marginX ? item.marginX : 3}
              marginY={item.marginY ? item.marginY : 0}
              key={i}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                component="img"
                height={1}
                width={1}
                src={item.url}
                alt={item.company}
                sx={{
                  filter: 'brightness(0) invert(1)',
                }}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    </React.Fragment>
  );
};

export default Partners;
