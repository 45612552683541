const alliesTextData = {
  en: {
    'landing.allies.1': 'We strengthen our expertise hand in hand',
    'landing.allies.2': 'with the main technological platforms',
    'landing.allies.3': 'in the market.',
  },
  es: {
    'landing.allies.1': 'Potenciamos nuestro expertise de la mano de las',
    'landing.allies.2': 'principales plataformas tecnológicas',
    'landing.allies.3': 'del mercado.',
  },
};

export default alliesTextData;